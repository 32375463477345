import { PRICES_INFO_MAP } from '../constants/StripeConstants'
import {Period, Plan} from '../types/Stripe'

export const getPlanPrice = (plan: Plan | undefined, period: Period, seats: number): number => {
	if (!plan) return 0

	const prices = PRICES_INFO_MAP[plan][period].priceBySeats
	const bucketSeats = Object.keys(prices).find(bucketSeats => seats <= parseInt(bucketSeats))
	return bucketSeats ? prices[bucketSeats] : 0
}
