import {SubscriptionStatus} from '../types/Stripe'

export const VALID_STATUS_SUBSCRIPTIONS: SubscriptionStatus[] = ['trialing', 'active', 'incomplete']
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ''
export const NARUS_ANALYTICS_APP_NAME = process.env.REACT_APP_NARUS_ANALYTICS_APP_NAME ?? ''
export const GROWTH_MONTHLY_PRICE_ID =  process.env.REACT_APP_STRIPE_PRICE_ID_GROWTH_MONTHLY
export const GROWTH_YEARLY_PRICE_ID =  process.env.REACT_APP_STRIPE_PRICE_ID_GROWTH_YEARLY
export const SCALE_MONTHLY_PRICE_ID =  process.env.REACT_APP_STRIPE_PRICE_ID_SCALE_MONTHLY
export const SCALE_YEARLY_PRICE_ID =  process.env.REACT_APP_STRIPE_PRICE_ID_SCALE_YEARLY

export const PRICES_INFO_MAP = {
	growth: {
		name: 'Growth',
		annual: {
			priceId: GROWTH_YEARLY_PRICE_ID,
			priceBySeats: {
				100: 4,
				200: 3.5,
				250: 3.25
			}
		},
		monthly: {
			priceId: GROWTH_MONTHLY_PRICE_ID,
			priceBySeats: {
				100: 4.8,
				200: 4.2,
				250: 3.9
			}
		}
	},
	scale: {
		name: 'Scale',
		annual: {
			priceId: SCALE_YEARLY_PRICE_ID,
			priceBySeats: {
				100: 6,
				200: 5.25,
				250: 4.88
			}
		},
		monthly: {
			priceId: SCALE_MONTHLY_PRICE_ID,
			priceBySeats: {
				100: 7.2,
				200: 6.3,
				250: 5.85
			}
		}
	}
}